body, html{
  margin: 0;
  padding: 0;
  /* This ensures that the body takes the full viewport */
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
}

@font-face {
  font-family: 'PressStart2P';
  src: local('PressStart2P'), url('../fonts/PressStart2P-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'PressStart2P', sans-serif;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  position: relative;
  width: 100%; /* Full width of its parent */
  height: 100%; /* Double the height of its parent */
  -ms-overflow-style: none;  
  scrollbar-width: none;  
  overflow-y: auto; 
} 

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.player-view {
 
  transform: perspective(1000px) rotateX(-20deg);
  
  /* Ensure the image stays within the frame during the perspective transformation */
  transform-origin: bottom center;
  
  /* Optional: add a border to simulate a screen */
  border: 5px solid #fff;
  
  /* Optional: add a shadow to simulate the ambient light of the theater */
  box-shadow: 0px 0px 50px 30px rgba(0, 0, 0, 0.8);
  z-index: 1;
}































/*
.darkness-layerrr {
  position: absolute;
  top: 0;
  left: 0;
  width: 10vh; 
  height: 10vh; 
  background-color: rgba(0, 0, 0, 0.7); 
  mask-image: url('../public/maaks-sprite.png');
  mask-size: cover;
  mask-position: center;
} */

/* .mask-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 10vh; 
  height: 10vh; 
  background-image: url('../public/maaks-sprite.png');
  background-size: cover;
  background-position: center;
} */